// Home
#leaderboard {
	position: relative;
	z-index: 1;
	margin-bottom: -500px;
	overflow: hidden;
	.container {
		position: relative;
	}
	.cycle-slideshow {
		height: 585px;
		max-height: calc(~"100vh - 140px");
		width: 100%;
		@media (max-width: @screen-sm-max) {
			max-height: calc(~"100vh - 80px");
		}
		@media (max-height: 480px) {
			max-height: none;
		}
		@media (max-width: @screen-xs-max) {
			height: 480px;
        }
		.slide {
			height: 585px;
			max-height: calc(~"100vh - 140px");
			width: 100%;
			@media (max-width: @screen-sm-max) {
				max-height: calc(~"100vh - 80px");
			}
			@media (max-height: 480px) {
				max-height: none;
			}
			@media (max-width: @screen-xs-max) {
				height: 480px;
	        }
			.content {
				position: absolute;
				z-index: 5;
				// bottom: 130px;
				left: 0;
				padding: 0 15px;
				width: 100%;

				top: 50%;
				-webkit-transform: translate(0,-50%);
				-moz-transform: translate(0,-50%);
				-ms-transform: translate(0,-50%);
				-o-transform: translate(0,-50%);
				transform: translate(0,-50%);
				/* @media (max-width: @screen-xs-max) {
					bottom: 90px;
						        } */
				h1, h2 {
					.h1;
					max-width: 700px;
				}
				.btn {
					margin-top: 25px;
					padding-top: 23px;
					padding-bottom: 23px;
					border-radius: 5px;
				}
			}
			.slide-image {
				position: absolute;
				z-index: 0;
				top: 0;
				right: 0;
				bottom: 0;
				width: calc(~"100vw - (100vw - 960px) / 2");
				background-color: #000;
				.bg {
					opacity: .75;
				}
				&:after {
					content: '';
					position: absolute;
					z-index: 5;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					opacity: .98;
					background: -moz-linear-gradient(45deg,  rgba(0,137,150,1) 0%, rgba(27,174,180,0) 53%);
					background: -webkit-linear-gradient(45deg,  rgba(0,137,150,1) 0%,rgba(27,174,180,0) 53%);
					background: linear-gradient(45deg,  rgba(0,137,150,1) 0%,rgba(27,174,180,0) 53%);
					filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#008996', endColorstr='#001baeb4',GradientType=1 );
				}
			}
		}
	}
	.cycle-pager {
		position: absolute;
		z-index: 110;
		bottom: 100px;
		right: -55px;
		@media (max-width: @screen-md-max) {
			bottom: 30px;
            right: 30px;
        }
        @media (max-width: @screen-xs-max) {
			bottom: 15px;
            right: 15px;
        }
		.outer-pager {
			display: block;
			border: 10px solid transparent;
			border-radius: 50%;
			cursor: pointer;
			.transition(all .2s);
		    .inner-pager {
				display: block;
				height: 5px;
				width: 5px;
				background-color: fade(#000, 20%);
				border-radius: 50%;
				.transition(all .2s);
				@media (max-width: @screen-md-max) {
		            background-color: fade(#fff, 20%);
		        }
		    }
		    & + .outer-pager {
		    	margin-top: 3px;
		    }
			&:hover, &.cycle-pager-active {
				border-color: fade(@brand-primary, 5%);
				@media (max-width: @screen-md-max) {
		            border-color: fade(#fff, 5%);
		        }
				.inner-pager {
					background-color: @brand-primary;
					@media (max-width: @screen-md-max) {
			            background-color: #fff;
			        }
				}
			}
		}
	}
}

#activity {
	position: relative;
	padding-top: 500px;
	padding-bottom: 85px;
	background-color: #f3f3f3;
	.wrapper {
		position: relative;
		z-index: 5;
	}
	h2 {
		margin: 65px 0 20px;
		color: fade(@brand-primary, 47%);
		font-size: 0.88rem;
		font-weight: 700;
		letter-spacing: .5px;
		text-transform: uppercase;
	}
	&:after {
		content: '';
		position: absolute;
		z-index: 0;
		top: 0;
		right: 0;
		bottom: 0;
		left: 50%;
		background-image: url(../img/eye.png);
		background-position: left 70px top -440px;
		background-repeat: no-repeat;
		opacity: .05;
	}
}

#about {
	
	.container {
		position: relative;
	}
	.social {
		position: absolute;
		top: 20px;
		right: 15px;
		color: #566294;
		font-size: 0;
		span, .fa {
			display: inline-block;
			vertical-align: middle;
		}
		span {
			font-size: 0.75rem;
			font-weight: 700;
			line-height: 1em;
		}
		.fa {
			margin-left: 20px;
			height: 50px;
			width: 50px;
			border: 1px solid #dadada;
			border-radius: 50%;
			font-size: 1.5rem;
			line-height: 43px;
			text-align: center;
			.transition(all .2s);
		}
		&:hover {
			.fa {
				background-color: #566294;
				color: #fff;
			}
		}
	}
	.article {
		padding: 45px 40px 70px;
		color: #979797;
		text-align: center;
		@media (max-width: @screen-sm-max) {
			padding: 75px 15px 60px;
		}
		@media (max-width: @screen-xs-max) {
			padding: 90px 0 60px;
		}
		h1 {
			.h3;
		}
		h1,h2,h3,h4,h5,h6 {
			margin-top: 0;
			margin-bottom: 25px;
			color: #38302f;
			font-weight: 700;
			line-height: normal;
		}
		p, ul, ol {
			margin: 0 0 20px;
			&:last-child {
				margin-bottom: 0;
			}
		}
		.btn {
			padding: 18px 30px;
			min-width: 0;
			border-radius: 5px;
			text-align: center;
			&:after {
				display: none;
			}
		}
	}
}

#news {
	padding-top: 60px;
	padding-bottom: 140px;
	background-color: #f3f3f3;
	h2 {
		margin: 0 0 15px;
		color: #38302f;
		font-size: 1.5rem;
		font-weight: 700;
	}
}