// Footer
#contact {
	margin-top: -85px;
	color: #fff;
	font-size: 1.5rem;
	font-weight: 600;
	line-height: 2.08em;
	text-align: center;
	cursor: default;
	.wrapper {
		padding: 36px 30px;
		background-color: @brand-primary;
		box-shadow: 0px 31px 42px 0px rgba(0, 0, 0, 0.17);
		@media (max-width: @screen-xs-max) {
			padding-left: 15px;
			padding-right: 15px;
		}
	}
	.form-group {
		display: none;
	}
	span.field-contact {
		display: inline-block;
		border-bottom: 1px dashed #7ac9d0;
		color: #7ac9d0;
		cursor: text;
		font-size: 1.13rem;
		font-weight: 400;
		line-height: 1.25em;
		text-align: left;
		outline: 0 !important;
		&:before {
			content: '';
			display: inline-block;
		}
		&.field-contact-name[contenteditable]:empty:before {
		    content: 'votre nom';
		}
		&.field-contact-society[contenteditable]:empty:before {
		    content: 'votre société';
		}
		&.field-contact-tel[contenteditable]:empty:before {
		    content: 'votre n° de téléphone';
		}
	}
	.btn {
		margin-left: 10px;
		padding: 13px 55px 13px 25px;
		min-width: 0;
		background-color: #fff;
		border-color: #fff;
		border-radius: 5px;
		color: @brand-primary;
		&:hover {
			border-color: darken(#fff, 15%);
			background-color: darken(#fff, 15%);
		}
	}
}

#footer {
	padding-top: 1px;
	background-color: #292624;
	.infos {
		padding-top: 45px;
		padding-bottom: 30px;
		color: #fff;
		font-size: 0.88rem;
		line-height: 1.71em;
		a {
			color: #fff;
			text-decoration: underline;
			opacity: .3;
			&:hover {
				text-decoration: none;
			}
		}
		.col-md-6:last-child {
			text-align: right;
			@media (max-width: @screen-sm-max) {
				margin-top: 30px;
				text-align: left;
			}
		}
	}
}

body.not-front {
	#footer {
		margin-top: 84px;
	}
}