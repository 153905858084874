body:not(.cke_editable) {
	padding-top: 140px;
	@media (max-width: @screen-sm-max) {
		padding-top: 80px;
	}
}
body.admin-menu.adminimal-menu {
	#header {
		top: 29px;
	}
}

.page-user {
	#main {
		padding-top: 60px;
		padding-bottom: 90px;
	}
}

.container {
	&.md-width {
		max-width: 960px;
	}
	.md {
		margin-left: auto;
		margin-right: auto;
		max-width: 960px;
	}
}
.v-align {
	position: absolute;
	top: 50%;
	left: 0;
	-webkit-transform: translate(0,-50%);
	-moz-transform: translate(0,-50%);
	-ms-transform: translate(0,-50%);
	-o-transform: translate(0,-50%);
	transform: translate(0,-50%);
}
.no-gap {
	padding-left: 0;
	padding-right: 0;
}
.no-marge {
	margin-left: 0;
	margin-right: 0;
}
.sm-gap {
	padding-left: 5px;
	padding-right: 5px;
}
.sm-marge {
	margin-left: -5px;
	margin-right: -5px;
}
 .bg {
	position: absolute;
	z-index: 0;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
} 

.same-height--sm {
	@media (min-width: @screen-sm-min) {
		display: -webkit-box;
		display: -moz-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
	}
}

// Animate
/* .animated {
	.animated;
} */
.os-animation {
	opacity: 0;
	@media (max-width: @screen-xs-max) {
		-webkit-animation-delay: 0s !important;
		-moz-animation-delay: 0s !important;
		-ms-animation-delay: 0s !important;
		-o-animation-delay: 0s !important;
		animation-delay: 0s !important;
	}
}

//  Types
h1,.h1 {
	margin-top: 0;
	margin-bottom: 0;
	color: #fff;
	font-weight: 700;
	@media (max-width: @screen-xs-max) {
		font-size: 2.25rem;
	}
}
h1,.h1,
h2,.h2 {
	line-height: normal;
}
h2,.h2,
h3,.h3,
h4,.h4 {
	margin-top: 0;
	margin-bottom: 30px;
}
h5,.h5,
h6,.h6 {
	margin-top: 0;
	margin-bottom: 0;
	color: #2e2925;
	font-weight: 700;
	line-height: 1.88em;
}
p, ul, ol {
	margin-top: 0;
	margin-bottom: 30px;
	&:last-child {
		margin-bottom: 0;
	}
}
.custom-list {
	margin: 0;
	padding: 0;
	list-style: none;
	li {
		position: relative;
		padding-left: 15px;
		&:before {
			content: '\f105';
			display: block;
			position: absolute;
			top: 5px;
			left: 0;
			font-family: 'FontAwesome';
			font-size: 1.13rem;
			line-height: 1em;
		}
	}
}

// Lead
p.lead {
	margin-top: 0;
	margin-bottom: 40px;
	color: @brand-primary;
	font-size: 1.13em;
	line-height: 1.5em;
}

// Evidence
p.evidence, div.evidence {
	margin-top: 40px;
	margin-bottom: 40px;
	padding: 40px;
	background-color: @brand-primary;
	color: #fff;
	font-size: 1.5rem;
	font-weight: 400;
	line-height: 1.5em;
	text-align: center;
	@media (max-width: @screen-sm-max) {
		padding: 30px 15px;
	}
	&:last-child {
		margin-bottom: 0;
	}

	a:not(.btn) {
		color: #fff;
	}
	.btn {
		margin-top: 10px;
		padding: 21px 40px;
		padding-right: 70px;
		min-width: 250px;
		font-weight: 600;
		&:after {
			right: 40px;
		}
	}
	.btn1 {
		background-color: transparent;
		border-color: #fff;
		color: #fff;
		&:hover {
			background-color: #fff;
			border-color: #fff;
			color: @brand-primary;
		}
	}
	.btn2 {
		background-color: #fff;
		border-color: #fff;
		color: @brand-primary;
		&:hover {
			background-color: darken(#fff, 15%);
			border-color: darken(#fff, 15%);
		}
	}
}

// Img
img {
	max-width: 100%;
	height: auto;

	&[style*="left"] {
		margin-right: @line-height-computed;
		margin-bottom: @line-height-computed;
	}

	&[style*="right"] {
		margin-left: @line-height-computed;
		margin-bottom: @line-height-computed;
	}
}

// Table
table {
	border: 1px solid #d8d8d8;
	margin-bottom: @line-height-computed;
	width: 100%;

	th {
		background-color: @brand-primary;
		color: #fff;
		text-transform: uppercase;
		font-weight: 400;
	}

	td {
		background-color: #f0f0f0;
	}

	tr.even td {
		background-color: darken(#f0f0f0, 5%);
	}

	td, th {
		padding: @line-height-computed/2 @line-height-computed;
	}
}

// Iframe responsive
.iframe-embed {
	display: block;
	position: relative;
	margin-bottom: @line-height-computed;
	padding: 0;
	padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;

	iframe {
		position: absolute;
	    top: 0;
	    bottom: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
	    border: 0;
	}
}

// Boutons
.btn {
	display: inline-block;
	vertical-align: middle;
	position: relative;
	margin: 0;
	padding: 26px 25px;
	padding-right: 60px;
	min-width: 240px;
	background-color: transparent;
	border: 2px solid transparent;
	border-radius: 70px;
	font-size: 0.88rem;
	font-weight: 700;
	letter-spacing: .5px;
	line-height: 1em;
	text-align: left;
	text-transform: uppercase;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	cursor: pointer;
	outline: 0 !important;
	white-space: normal;
	.transition(all .2s);
	&:after {
		content: '\f061';
		position: absolute;
		top: 50%;
		right: 25px;
		margin-top: -8px;
		font-family: 'FontAwesome';
		font-size: 1rem;
		line-height: 1em;
	}
	&.btn-primary, &.btn-default, &.btn1 {
		background-color: @brand-primary;
		border-color: @brand-primary;
		color: #fff;
		&:hover {
			background-color: darken(@brand-primary, 15%);
			border-color: darken(@brand-primary, 15%);
		}
	}
	&.btn-border, &.btn2 {
		border-color: #fff;
		color: #fff;
		&:hover {
			background-color: #fff;
			color: @brand-primary;
		}
	}
	&.btn-back {
		margin-bottom: 30px;
		padding: 26px 40px;
		padding-left: 70px;
		min-width: 0;
		&:after {
			content: '\f060';
			right: 0;
			left: 40px;
		}
	}
}

//  Items
.item {
	&--activity {
		display: block;
		position: relative;
		margin: 5px 0;
		padding: 70px 60px 50px;
		background-color: #fff;
		box-shadow: 0px 16px 40px 0px rgba(0, 0, 0, 0.2);
		@media (min-width: @screen-sm-min) {
			height: calc(~"100% - 10px");
		}
		@media (max-width: @screen-sm-max) {
			padding: 35px 30px 25px;
		}
		@media (max-width: @screen-xs-max) {
			padding: 30px 15px;
		}
		.content {
			position: relative;
			z-index: 5;
		}
		h3 {
			margin: 0 0 25px;
			color: #585450;
			font-weight: 700;
			.transition(all .35s);
		}
		p, ul {
			margin: 0 0 20px;
			color: #979797;
			.transition(all .35s);
			&:last-child {
				margin-bottom: 0;
			}
		}
		p {
			&.more {
				font-size: 0.88rem;
				font-weight: 700;
				letter-spacing: .5px;
				text-transform: uppercase;
				.fa {
					margin-left: 10px;
					font-size: 1.25rem;
				}
			}
		}
		&:after {
			content: '';
			position: absolute;
			z-index: 0;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			opacity: 0;
			background: rgb(0,137,150);
			background: -moz-linear-gradient(45deg,  rgba(0,137,150,1) 0%, rgba(42,9,97,1) 100%);
			background: -webkit-linear-gradient(45deg,  rgba(0,137,150,1) 0%,rgba(42,9,97,1) 100%);
			background: linear-gradient(45deg,  rgba(0,137,150,1) 0%,rgba(42,9,97,1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#008996', endColorstr='#2a0961',GradientType=1 );
			.transition(all .35s);
		}
		&:hover {
			&:after {
				opacity: .98;
			}
			h3, p, ul {
				color: #fff;
			}
		}
	}
	&--news {
		display: block;
		margin: 20px 0;
		.transition(all .35s);
		.wrapper {
			height: 420px;
			overflow: hidden;
			.inner-wrapper {
				.transition(all .35s);
			}
		}
		.img-wrapper {
			position: relative;
			height: 300px;
			width: 100%;
		}
		.content {
			padding: 23px 40px;
			background-color: transparent;
			color: @text-color;
			.transition(all .35s);
			@media (max-width: @screen-md-max) {
				padding-left: 30px;
				padding-right: 30px;
			}
			@media (max-width: @screen-xs-max) {
				padding-left: 15px;
				padding-right: 15px;
			}
		}
		h3 {
			margin: 0;
			min-height: 50px;
			color: #000;
			font-size: 1rem;
			font-weight: 700;
			line-height: 1.5em;
		}
		.date {
			display: block;
			color: #000;
			font-size: 0.75rem;
			line-height: 2em;
			text-transform: uppercase;
		}
		p {
			margin: 0;
			padding: 20px 0 30px;
			height: 150px;
			line-height: 1.5em;
			opacity: 0;
			.transition(all .35s);
			span {
				color: #2e2925;
				text-decoration: underline;
			}
		}
		&:hover {
			box-shadow: 0px 16px 40px 0px rgba(0, 0, 0, 0.2);
			.content {
				background-color: #fff;
			}
			&.descOn {
				.wrapper {
					.inner-wrapper {
						transform: translate(0,-150px);
					}
				}
				p {
					opacity: 1;
				}
			}
		}
	}
	&--ref {
		display: block;
		margin: 15px 0;
		background-color: #fff;
		box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2); 
		.img-wrapper {
			position: relative;
			height: 190px;
			background-color: #f5f5f5;
			img {
				position: absolute;
			    top: 50%;
			    left: 50%;
				height: auto;
				max-height: 100%;
				width: auto;
				max-width: 100%;
				-webkit-transform: translate(-50%,-50%);
			    -moz-transform: translate(-50%,-50%);
			    -ms-transform: translate(-50%,-50%);
			    -o-transform: translate(-50%,-50%);
			    transform: translate(-50%,-50%);
			}
		}
		.content {
			position: relative;
			padding: 40px 60px;
			min-height: 250px;
			@media (max-width: @screen-md-max) {
				padding-left: 20px;
				padding-right: 20px;
			}
			@media (max-width: @screen-sm-max) {
				padding-left: 30px;
				padding-right: 30px;
			}
			@media (max-width: @screen-xs-max) {
				padding-left: 15px;
				padding-right: 15px;
			}
			.wrapper {
				position: relative;
				z-index: 5;
			}
			&:after {
				content: '';
				position: absolute;
				z-index: 0;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				opacity: 0;
				background: rgb(0,137,150);
				background: -moz-linear-gradient(45deg,  rgba(0,137,150,1) 0%, rgba(42,9,97,1) 100%);
				background: -webkit-linear-gradient(45deg,  rgba(0,137,150,1) 0%,rgba(42,9,97,1) 100%);
				background: linear-gradient(45deg,  rgba(0,137,150,1) 0%,rgba(42,9,97,1) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#008996', endColorstr='#2a0961',GradientType=1 );
				.transition(all .35s);
			}
		}
		h3 {
			margin: 0;
			color: #000;
			min-height: 50px;
			font-size: 1rem;
			font-weight: 700;
			line-height: 1.5em;
			.transition(all .35s);
		}
		p {
			margin: 0;
			color: @text-color;
			line-height: 1.5em;
			.transition(all .35s);
			span {
				color: #2e2925;
				text-decoration: underline;
				.transition(all .35s);
			}
		}
		
		&_partn {
			&:hover {
				.content {
					&:after {
						opacity: .98;
					}
				}
				h3, p, span {
					color: #fff;
				}
			}
		}
	}
}

.pagination {
	display: inline-block;
	margin: 40px 0 0;
	padding: 0;
    border-radius: 2px;
    font-size: 0;
    > li {
    	display: inline-block;
    	> a, > span {
    		display: block;
			position: relative;
			float: none;
			margin: 0;
			padding: 0;
			height: 40px;
			width: 50px;
			background-color: #fff !important;
			border: 1px solid #d6d6d6 !important;
			border-radius: 0;
			color: #666666;
			font-size: 0.94rem;
			font-weight: 400;
			line-height: 40px;
			text-decoration: none;
			.transition(all .2s);
    	}
    	& + li {
    		margin-left: -1px;
    	}
    	&:first-child {
    		> a, > span {
	    		border-radius: 3px 0 0 3px;
	    	}
    	}
    	&:last-child {
    		> a, > span {
	    		border-radius: 0 3px 3px 0;
	    	}
    	}
    	&.pager-first, &.prev, &.next, &.pager-last {
    		> a, > span {
    			color: #444444;
				width: 100px;
				@media (max-width: @screen-xs-max) {
					width: 90px;
				}
    		}
    	}
    	&.active, &:hover {
	    	> a, > span {
    			background-color: @brand-primary !important;
				color: #fff;
	    	}
	    }
    }
}

#node-details {
	padding-top: 45px;
	padding-bottom: 100px;
	.img-details {
		float: left;
		margin: 0 30px 30px 0;
		@media (max-width: @screen-xs-max) {
			float: none;
			margin: 0 0 30px;
		}
	}
}


// Overlay
body.overlayOn {
	overflow: hidden;
}
#customOverlay {
	position: fixed;
	z-index: 2020;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: hidden;
	opacity: 0;
	visibility: hidden;
	.transition(all .35s);
	.wrapper {
		position: relative;
		margin: 160px auto;
		padding: 0 15px;
		width: 1200px;
		max-width: 100%;
		-webkit-transform: translate(0, 100%);
	    -ms-transform: translate(0, 100%);
	    transform: translate(0, 100%);
	    .transition(all .35s);
	    @media (max-width: @screen-sm-max) {
			padding: 0;
	    }
	    .overlay-content {
	    	background-color: #fff;
	    }
	}
	&.in {
		opacity: 1;
		visibility: visible;
		overflow-x: hidden;
    	overflow-y: auto;
    	.wrapper {
    		-webkit-transform: translate(0, 0);
		    -ms-transform: translate(0, 0);
		    transform: translate(0, 0);
    	}
	}
}
.overlayBg {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 2000;
	background-color: #000;
	opacity: 0;
	visibility: hidden;
	.transition(all .35s);
	&.in {
		opacity: .5;
		visibility: visible;
	}
}
.closeOverlay {
	display: block;
	position: absolute;
	top: -115px;
	left: 50%;
	margin-left: -25px;
	height: 50px;
	width: 50px;
	background-image: url(../img/icon-close.png);
	background-repeat: no-repeat;
	background-size: 50px auto;
	.transition(all .2s);
	&:hover {
		background-image: url(../img/icon-close--hover.png);
	}
}

// Loading
#loading {
	display: none;
	position: fixed;
	z-index: 2010;
	top: 50%;
	left: 50%;
	margin-top: -20px;
	margin-left: -20px;
	height: 40px;
	width: 40px;
	.circle {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		&:before {
			content: '';
			display: block;
			margin: 0 auto;
			height: 15%;
			width: 15%;
			background-color: #fff;
			border-radius: 100%;
			-webkit-animation: exp-spinning 1.2s infinite ease-in-out both;
			animation: exp-spinning 1.2s infinite ease-in-out both;
		}
		&:nth-child(2) {
			-webkit-transform: rotate(30deg);
			-ms-transform: rotate(30deg);
			transform: rotate(30deg);
			&:before {
				-webkit-animation-delay: -1.1s;
          		animation-delay: -1.1s;
			}
		}
		&:nth-child(3) {
			-webkit-transform: rotate(60deg);
			-ms-transform: rotate(60deg);
			transform: rotate(60deg);
			&:before {
				-webkit-animation-delay: -1s;
          		animation-delay: -1s;
			}
		}
		&:nth-child(4) {
			-webkit-transform: rotate(90deg);
			-ms-transform: rotate(90deg);
			transform: rotate(90deg);
			&:before {
				-webkit-animation-delay: -0.9s;
          		animation-delay: -0.9s;
			}
		}
		&:nth-child(5) {
			-webkit-transform: rotate(120deg);
			-ms-transform: rotate(120deg);
			transform: rotate(120deg);
			&:before {
				-webkit-animation-delay: -0.8s;
          		animation-delay: -0.8s;
			}
		}
		&:nth-child(6) {
			-webkit-transform: rotate(150deg);
			-ms-transform: rotate(150deg);
			transform: rotate(150deg);
			&:before {
				-webkit-animation-delay: -0.7s;
          		animation-delay: -0.7s;
			}
		}
		&:nth-child(7) {
			-webkit-transform: rotate(180deg);
			-ms-transform: rotate(180deg);
			transform: rotate(180deg);
			&:before {
				-webkit-animation-delay: -0.6s;
          		animation-delay: -0.6s;
			}
		}
		&:nth-child(8) {
			-webkit-transform: rotate(210deg);
			-ms-transform: rotate(210deg);
			transform: rotate(210deg);
			&:before {
				-webkit-animation-delay: -0.5s;
          		animation-delay: -0.5s;
			}
		}
		&:nth-child(9) {
			-webkit-transform: rotate(240deg);
			-ms-transform: rotate(240deg);
			transform: rotate(240deg);
			&:before {
				-webkit-animation-delay: -0.4s;
          		animation-delay: -0.4s;
			}
		}
		&:nth-child(10) {
			-webkit-transform: rotate(270deg);
			-ms-transform: rotate(270deg);
			transform: rotate(270deg);
			&:before {
				-webkit-animation-delay: -0.3s;
          		animation-delay: -0.3s;
			}
		}
		&:nth-child(11) {
			-webkit-transform: rotate(300deg);
			-ms-transform: rotate(300deg);
			transform: rotate(300deg);
			&:before {
				-webkit-animation-delay: -0.2s;
          		animation-delay: -0.2s;
			}
		}
		&:nth-child(12) {
			-webkit-transform: rotate(330deg);
			-ms-transform: rotate(330deg);
			transform: rotate(330deg);
			&:before {
				-webkit-animation-delay: -0.1s;
          		animation-delay: -0.1s;
			}
		}
	}
}
@-webkit-keyframes exp-spinning {
  0%, 39%, 100% { opacity: 0; }
  40% { opacity: 1; }
}
@keyframes exp-spinning {
  0%, 39%, 100% { opacity: 0; }
  40% { opacity: 1; } 
}

// Paragraphs
.paragraphs-items {
	padding-bottom: 10px;
}
.content-texte {
	margin-top: 55px;
	margin-bottom: 45px;
	a:not(.btn) {
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}
	ul {
		.custom-list;
	}
	@media (max-width: @screen-sm-max) {
		.col-md-6 {
			& + .col-md-6 {
				margin-top: 30px;
			}
		}
	}
}
.content-evidence {
	margin-top: 45px;
	margin-bottom: 80px;
	.wrapper {
		position: relative;
		padding: 60px 55px;
		background-color: @brand-primary;
		box-shadow: 0px 30px 40px 0px rgba(0, 0, 0, 0.11); 
		overflow: hidden;
		@media (max-width: @screen-sm-max) {
			padding: 40px 30px;
		}
		@media (max-width: @screen-xs-max) {
			padding: 30px 20px;
		}
		&:after {
			content: '';
			position: absolute;
			z-index: 0;
			bottom: -90px;
			right: 25%;
			height: 1080px;
			width: 1080px;
			border-radius: 50%;
			background-color: @brand-primary-light;
		}
		.content {
			position: relative;
			z-index: 5;
		}
	}
	h3 {
		margin: 0;
		color: #fff;
		font-weight: 400;
		line-height: 1.5em;
	}
	.btn {
		@media (min-width: @screen-sm-min) {
			.v-align;
			left: auto;
			right: 0;
		}
		font-weight: 600;
		@media (max-width: @screen-xs-max) {
			margin: 30px 15px 0;
		}
	}
}
.content-galerie {
	margin-top: 45px;
	margin-bottom: 65px;
	.carousel-caption {
	    position: absolute;
	    z-index: 10;
	    right: auto;
	    bottom: 0;
	    left: auto;
	    padding: 20px 25px;
	    background-color: #edf5f5;
	    color: @brand-primary-light;
	    font-size: 1.13rem;
	    font-style: italic;
	    font-weight: 400;
	    line-height: 1.67em;
	    text-align: left;
	    text-shadow: none;
	    @media (max-width: @screen-xs-max) {
			display: none;
		}
	}
	.carousel-indicators {
		top: 30px;
		bottom: auto;
		margin-bottom: 0;
		@media (max-width: @screen-xs-max) {
			top: auto;
			bottom: 15px;
		}
	}
}

.alert {
	margin-top: 30px;
	margin-bottom: 30px;
}

.view-faq .view-content {
    padding-top: 70px;
    padding-bottom: 100px;
}

.panel-group .panel+.panel {
    border-top: 1px solid #e5e5e5;
}
.panel-group .panel-heading .panel-title {
    margin: 0;
    color: @brand-primary;
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.25em;
}
.panel-group .panel-heading .panel-title a {
    display: block;
    padding: 20px 50px 20px 0;
    color: @brand-primary;
    text-decoration: none;
    transition: all .2s;
}
.panel-group .panel-heading .panel-title a:hover {
	color: @brand-primary-light;
}
.panel-group .panel-collapse .panel-body {
    padding-top: 10px;
    padding-bottom: 40px;
    color: #7f7f7f;
}

.cta-fixed {
	display: block;
	position: fixed;
	z-index: 900;
	top: 180px;
	right: 0;
	background-color: #1d1d1b;
	border-radius: 4px 0 0 4px;
	color: #fff !important;
	font-size: 0;
	outline: 0 !important;
	text-decoration: none !important;
	transition: all .5s;
	.fa, span {
		display: inline-block;
		vertical-align: middle;
	}
	.fa {
		width: 48px;
		height: 48px;
		font-size: 24px;
		line-height: 48px;
		text-align: center;
	}
	span {
		padding: 10px 15px;
		max-width: 300px;
		font-size: 1rem;
		line-height: 1.5em;
		white-space: nowrap;
		transition: all .2s;
		@media (max-width: @screen-md-max) {
			padding: 0;
			max-width: 0;
		}
	}
	&.scrolled {
		span {
			padding: 0;
			max-width: 0;
		}
	}
	&:hover {
		span {
			padding: 10px 15px;
			max-width: 300px;
		}
	}
}