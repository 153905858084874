// Webform
.node-webform {
	padding-top: 35px;
	padding-bottom: 105px;
	a:not(.btn) {
		color: @brand-primary;
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}
	.form-before {
		.row {
			margin-bottom: 35px;
			@media (max-width: @screen-sm-max) {
				.col-md-6 {
					& + .col-md-6 {
						margin-top: 30px;
					}
				}
			}
		}
	}
	.form-content {
		margin-top: 40px;
		background-color: fade(@brand-primary, 9%);
		.wrapper {
			padding: 75px 0 65px;
		}
		div[class*="col-"] {
			padding-left: 30px;
			padding-right: 30px;
			@media (max-width: @screen-sm-max) {
				padding-left: 15px;
				padding-right: 15px;
			}
		}
	}
}

.form-group {
    margin-bottom: 25px;
}
label {
    display: block;
    margin: 0 0 10px;
    color: @brand-primary;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.88em;
}
.form-control {
	padding: 15px;
	height: 60px;
    width: 100%;
    background-color: #fff;
    border: 0;
    border-radius: 0;
    -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.24);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.24);
}
.form-control, output {
	display: block;
	color: #404040;
    font-size: 0.94rem;
    font-weight: 500;
    line-height: normal;
}
textarea {
	border-radius: 0 !important;
}

::-webkit-input-placeholder {
   color: #404040;
}

:-moz-placeholder {
   color: #404040;
}

::-moz-placeholder {
   color: #404040;
}

:-ms-input-placeholder {  
   color: #404040;
}

.webform-client-form {
	.form-actions {
		padding: 0 30px;
		text-align: right;
		@media (max-width: @screen-sm-max) {
			padding: 0 15px;
		}
		.btn {
			margin-top: 25px;
			padding-left: 50px;
			font-weight: 600;
			&:after {
				right: 45px;
			}
		}
	}
}