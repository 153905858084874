// Header
#header {
    position: fixed;
    z-index: 500;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    .transition(all .35s);
    .container {
        position: relative;
    }
    .logo {
        display: block;
        float: left;
        margin: 20px 0;
        margin-left: -15px;
        padding: 15px;
        .transition(all .35s);
        @media (max-width: @screen-md-max) {
            margin-top: 30px;
            margin-bottom: 30px;
        }
        @media (max-width: @screen-sm-max) {
            margin-top: 0;
            margin-bottom: 0;
        }
        img {
            width: 300px;
            @media (max-width: @screen-md-max) {
                width: 210px;
            }
        }
    }
    .mainNav {
        float: right;
        margin-top: 40px;
        padding: 25px 0;
        .transition(all .35s);
        @media (max-width: @screen-sm-max) {
            float: none;
            margin: 0;
            padding: 0;
        }
        > ul {
            float: none;
            margin: 0;
            padding: 0;
            list-style: none;
            > li {
                float: left;
                @media (max-width: @screen-sm-max) {
                    float: none;
                }
                > a, > .nolink {
                    display: block;
                    padding: 18px 0 16px;
                    background-color: transparent;
                    border-bottom: 2px solid transparent;
                    color: #2f2a26;
                    font-size: 0.88rem;
                    font-weight: 700;
                    line-height: 1em;
                    text-decoration: none;
                    text-transform: uppercase;
                    .transition(all .2s);
                    @media (max-width: @screen-sm-max) {
                        padding-left: 15px;
                        padding-right: 15px;
                        color: #fff;
                    }
                    .caret {
                        display: none;
                    }
                }
                &:hover, &.active, &.active-trail {
                    > a, > .nolink {
                        color: @brand-primary;
                        @media (max-width: @screen-sm-max) {
                            color: #fff;
                            text-decoration: underline;
                        }
                    }
                }
                &.open {
                    > a, > .nolink {
                        border-color: #2e2925;
                    }
                }
                & + li {
                    margin-left: 30px;
                    @media (max-width: @screen-sm-max) {
                        margin-left: 0;
                    }
                }
                @media (min-width: @screen-md-min) {
                    &:hover {
                        > .dropdown-menu {
                            display: block;
                        }
                    }
                }
                > .dropdown-menu {
                    position: absolute;
                    z-index: 1000;
                    top: 100%;
                    left: 50%;
                    float: none;
                    margin: 0;
                    padding: 20px 45px 40px 35px;
                    min-width: 300px;
                    list-style: none;
                    background-color: #fff;
                    border: 0;
                    border-radius: 5px;
                    -webkit-box-shadow: 0px 13px 35px 0px rgba(0, 0, 0, 0.5);
                    -moz-box-shadow: 0px 13px 35px 0px rgba(0, 0, 0, 0.5);
                    box-shadow: 0px 13px 35px 0px rgba(0, 0, 0, 0.5);
                    @media (min-width: @screen-md-min) {
                        -webkit-transform: translate(-50%,0);
                        -moz-transform: translate(-50%,0);
                        -ms-transform: translate(-50%,0);
                        -o-transform: translate(-50%,0);
                        transform: translate(-50%,0);
                    }
                    @media (max-width: @screen-sm-max) {
                        position: relative;
                        top: auto;
                        left: auto;
                        padding: 15px 0;
                        min-width: 0;
                        background-color: fade(#fff, 90%);
                        border-radius: 0;
                        text-align: center;
                        -webkit-box-shadow: none;
                        -moz-box-shadow: none;
                        box-shadow: none;
                    }
                    > li {
                        > a, > .nolink {
                            display: block;
                            position: relative;
                            padding: 0;
                            padding-left: 10px;
                            background-color: transparent;
                            color: #131313;
                            font-size: 0.88rem;
                            font-weight: 400;
                            line-height: 1.5em;
                            white-space: normal;
                            .transition(all .2s);
                            &:before {
                                content: '\f105';
                                position: absolute;
                                top: 0;
                                left: 0;
                                font-family: 'FontAwesome';
                            }
                            @media (max-width: @screen-sm-max) {
                                padding: 15px;
                                &:before {
                                    display: none;
                                }
                            }
                        }
                        &:hover, &.active, &.active-trail {
                            > a, > .nolink {
                                color: @brand-primary;
                            }
                        }
                        & + li {
                            margin-top: 15px;
                            @media (max-width: @screen-sm-max) {
                                margin-top: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    .topNav {
        position: absolute;
        top: 25px;
        right: 15px;
        .transition(all .35s);
        @media (max-width: @screen-sm-max) {
            position: relative;
            margin-top: 60px;
            top: auto;
            right: auto;
        }
        > ul {
            margin: 0;
            padding: 0;
            list-style: none;
            > li {
                float: left;
                @media (max-width: @screen-sm-max) {
                    float: none;
                }
                > a, > .nolink {
                    display: block;
                    padding: 10px 0;
                    background-color: transparent;
                    color: #161616;
                    font-size: 0.88rem;
                    font-weight: 400;
                    line-height: 1em;
                    text-decoration: none;
                    .transition(all .2s);
                    @media (max-width: @screen-sm-max) {
                        padding: 15px;
                        color: #fff;
                    }
                    .caret {
                        display: none;
                    }
                }
                &:hover, &.active, &.active-trail {
                    > a, > .nolink {
                        color: @brand-primary;
                        @media (max-width: @screen-sm-max) {
                            color: #fff;
                            text-decoration: underline;
                        }
                    }
                }
                & + li {
                    margin-left: 22px;
                    @media (max-width: @screen-sm-max) {
                        margin-left: 0;
                    }
                }
                @media (min-width: @screen-md-min) {
                    &:hover {
                        > .dropdown-menu {
                            display: block;
                        }
                    }
                }
                > .dropdown-menu {
                    position: absolute;
                    z-index: 1000;
                    top: 100%;
                    left: 50%;
                    float: none;
                    margin: 0;
                    padding: 20px 15px;
                    min-width: 150px;
                    list-style: none;
                    background-color: #fff;
                    border: 0;
                    border-radius: 5px;
                    -webkit-box-shadow: 0px 13px 35px 0px rgba(0, 0, 0, 0.5);
                    -moz-box-shadow: 0px 13px 35px 0px rgba(0, 0, 0, 0.5);
                    box-shadow: 0px 13px 35px 0px rgba(0, 0, 0, 0.5);
                    @media (min-width: @screen-md-min) {
                        -webkit-transform: translate(-50%,0);
                        -moz-transform: translate(-50%,0);
                        -ms-transform: translate(-50%,0);
                        -o-transform: translate(-50%,0);
                        transform: translate(-50%,0);
                    }
                    @media (max-width: @screen-sm-max) {
                        position: relative;
                        top: auto;
                        left: auto;
                        padding: 15px 0;
                        min-width: 0;
                        background-color: fade(#fff, 90%);
                        border-radius: 0;
                        text-align: center;
                        -webkit-box-shadow: none;
                        -moz-box-shadow: none;
                        box-shadow: none;
                    }
                    > li {
                        > a, > .nolink {
                            display: block;
                            position: relative;
                            padding: 0;
                            padding-left: 10px;
                            background-color: transparent;
                            color: #161616;
                            font-size: 0.88rem;
                            font-weight: 400;
                            line-height: 1em;
                            white-space: normal;
                            .transition(all .2s);
                            &:before {
                                content: '\f105';
                                position: absolute;
                                top: 0;
                                left: 0;
                                font-family: 'FontAwesome';
                            }
                            @media (max-width: @screen-sm-max) {
                                padding: 15px;
                                &:before {
                                    display: none;
                                }
                            }
                        }
                        &:hover, &.active, &.active-trail {
                            > a, > .nolink {
                                color: @brand-primary;
                            }
                        }
                        & + li {
                            margin-top: 15px;
                            @media (max-width: @screen-sm-max) {
                                margin-top: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    &.scrolled {
        box-shadow: 0px 1px 0px 0px rgba(211, 208, 207, 1);
        .logo {
            margin-top: 0;
            margin-bottom: 0;
        }
        .mainNav {
            margin-top: 0;
            @media (max-width: @screen-md-max) {
                padding-top: 15px;
                padding-bottom: 15px;
            }
            @media (max-width: @screen-sm-max) {
                padding-top: 0;
                padding-bottom: 0;
            }
        }
        .topNav {
            @media (min-width: @screen-md-min) {
                top: -100%;
                opacity: 0;
            }
        }
    }
}
.trigger-menu {
    position: relative;
    z-index: 1;
    float: right;
    margin-right: -15px;
    padding: 15px;
    svg {
    	display: block;
        fill: @brand-primary !important;
    	rect {
    		-webkit-transform-origin: left center;
    		-moz-transform-origin: left center;
    		-ms-transform-origin: left center;
    		-o-transform-origin: left center;
    		transform-origin: left center;
    		-webkit-transform: rotate(0);
    		-moz-transform: rotate(0);
    		-ms-transform: rotate(0);
    		-o-transform: rotate(0);
    		transform: rotate(0);
    		.transition(all .2s);
    	}
    }
    &.open {
    	svg {
    		rect {
                fill: #fff !important;
    			&:nth-child(1) {
    				-webkit-transform: rotate(45deg);
    				-moz-transform: rotate(45deg);
    				-ms-transform: rotate(45deg);
    				-o-transform: rotate(45deg);
    				transform: rotate(45deg);
    				x: 3px;
    			}
    			&:nth-child(2) {
    				width: 0%;
    				opacity: 0;
    			}
    			&:nth-child(3) {
    				-webkit-transform: rotate-(45deg);
    				-moz-transform: rotate(-45deg);
    				-ms-transform: rotate(-45deg);
    				-o-transform: rotate(-45deg);
    				transform: rotate(-45deg);
    				x: 3px;
    			}
    		}
    	}
    }
}

@media (max-width: @screen-sm-max) {
    nav#nav {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 60px 0;
        text-align: center;
        visibility: hidden;
        opacity: 0;
        background-color: fade(@brand-primary,90%);
        overflow-x: auto;
        overflow-y: scroll;
        .transition(all .35s);
    }
}

body.open-menu {
    @media (max-width: @screen-sm-max) {
        height: 100%;
        overflow: hidden;
        nav#nav {
            visibility: visible;
            opacity: 1;
        }
    }
}

#banner {
    position: relative;
    height: 265px;
    background-color: fade(#2e2925, 20%);
    .banner-title {
        position: absolute;
        z-index: 5;
        bottom: 35px;
        left: 0;
        width: 100%;
        .date {
            display: inline-block;
            margin: 0;
            color: #fff;
            font-size: 0.88rem;
            font-weight: 600;
            line-height: normal;
        }
        .cat {
            display: inline-block;
            margin: 0;
            padding: 7px 15px;
            background-color: #111;
            color: #fff;
            font-size: 0.75rem;
            font-weight: 500;
            line-height: normal;
            text-transform: uppercase;
        }
    }
    &.banner--image {
        background-color: #000;
        .bg {
            opacity: .5;
        }
        &:after {
            content: '';
            position: absolute;
            z-index: 1;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: .98;
            background: -moz-linear-gradient(70deg,  rgba(0,137,150,1) 0%, rgba(27,174,180,0) 100%);
            background: -webkit-linear-gradient(70deg,  rgba(0,137,150,1) 0%,rgba(27,174,180,0) 100%);
            background: linear-gradient(70deg,  rgba(0,137,150,1) 0%,rgba(27,174,180,0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#008996', endColorstr='#001baeb4',GradientType=1 );
        }
    }
    &.banner-news {
        height: 350px;
        h1,.h1 {
            margin-bottom: 15px;
            font-size: 2.25rem;
        }
        .banner-title {
            bottom: 0;
        }
        .bg {
            opacity: .8;
        }
        
    }
}
body.page-partenaires, body.page-actualites, body.page-references, body.page-sitemap {
    #banner {
        background-color: #000;
        &:before, &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
        &:before {
            z-index: 0;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            opacity: .5;
        }
        &:after {
            z-index: 1;
            opacity: .98;
            background: -moz-linear-gradient(70deg,  rgba(0,137,150,1) 0%, rgba(27,174,180,0) 100%);
            background: -webkit-linear-gradient(70deg,  rgba(0,137,150,1) 0%,rgba(27,174,180,0) 100%);
            background: linear-gradient(70deg,  rgba(0,137,150,1) 0%,rgba(27,174,180,0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#008996', endColorstr='#001baeb4',GradientType=1 );
        }
    }
}
body.page-partenaires {
    #banner {
        &:before {
            background-image: url('../img/banner-partenaires.jpg');
        }
    }
}
body.page-actualites {
    #banner {
        &:before {
            background-image: url('../img/banner-actualites.jpg');
        }
    }
}
body.page-references {
    #banner {
        &:before {
            background-image: url('../img/banner-references.jpg');
        }
    }
}
body.page-sitemap {
    #banner {
        &:before {
            background-image: url('../img/banner-sitemap.jpg');
        }
    }
    #main {
        padding-top: 45px;
        padding-bottom: 100px;
    }
}

.breadcrumb {
    margin: 0;
    padding: 0;
    list-style: none;
    background-color: transparent;
    border-radius: 0;
    @media (max-width: @screen-xs-max) {
        display: none;
    }
    > li {
        display: inline-block;
        a {
            color: #fff;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
        & + li {
            &:before {
                content: "/";
                padding: 0 5px;
                color: #fff;
            }
        }
    }
    > .active {
        color: #fff;
    }
}