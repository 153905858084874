// View
.view-filters {
	padding: 12px 0;
	background-color: #f3f3f3;
	form {
		float: right;
		@media (max-width: @screen-xs-max) {
			float: none;
		}
	}
	.views-exposed-widget {
		font-size: 0;
	}
	label, .views-widget {
		display: inline-block;
		vertical-align: middle;
	}
	.form-group {
		margin: 0;
	}
	label {
		margin: 0;
		margin-right: 30px;
		color: #404040;
		font-size: 0.94rem;
		font-weight: 500;
		line-height: normal;
		@media (max-width: @screen-xs-max) {
			display: block;
			margin: 0 0 10px;
		}
	}
	.form-control {
		padding: 0 20px;
		height: 45px;
		width: 100%;
		min-width: 220px;
		background-color: #fff;
		border: 1px solid #cdcdcd;
		border-radius: 4px;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;
	}
}

.view-wrapper {
	padding-top: 40px;
	padding-bottom: 55px;
}